








import { Vue, Component } from 'vue-property-decorator'

import NavBar from '@/components/NavBar.vue'

@Component({ components: { NavBar } })
export default class App extends Vue {}
