




























































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import NavBar from '@/components/NavBar.vue'
import KeaButton from '@/components/KeaButton.vue'
import KeaBadge from '@/components/KeaBadge.vue'
import KeaAlert from '@/components/KeaAlert.vue'
import KeaAccordion from '@/components/KeaAccordion.vue'
import KeaTable from '@/components/KeaTable.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import NewApiKeyModal from '@/components/NewApiKeyModal.vue'

import {
  getTeam,
  TeamDetail,
  ApiKey,
  getTeamApiKeys,
  createTeamApiKey,
  deleteTeamApiKey,
} from '@/api/teams'

import { unregisterGit } from '@/api/oauth'

@Component({
  components: {
    NavBar,
    KeaButton,
    KeaBadge,
    KeaAlert,
    KeaAccordion,
    KeaTable,
    ConfirmationModal,
    NewApiKeyModal,
  },
})
export default class Settings extends Vue {
  isOpen = false
  loading = true
  successMessage = ''
  errorMessage = ''
  team: TeamDetail = {
    name: '',
    members: [],
    pending_invites: [],
    git_connections: [],
  }

  focusedApi: any = null
  isDeleteConfirmationModalOpen = false
  isNewApiKeyModalOpen = false

  apiKeys: ApiKey[] = []

  async getTeam(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.team = await getTeam(authToken)
  }

  mounted(): void {
    this.getTeam()
    this.getApiKeys()
    setTimeout(() => {
      this.loading = false
    }, 300)
  }

  connectGithub(): void {
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${process.env.VUE_APP_GITHUB_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_GITHUB_CALLBACK}&response_type=code&scope=repo`
  }
  connectBitbucket(): void {
    window.location.href = `https://bitbucket.org/site/oauth2/authorize?client_id=${process.env.VUE_APP_BITBUCKET_CLIENT_ID}&response_type=code`
  }

  async disconnectGithub(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    await unregisterGit('github', authToken)
    this.getTeam()
  }
  async disconnectBitbucket(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    await unregisterGit('bitbucket', authToken)
    this.getTeam()
  }

  newApiKey(): void {
    console.log('new api key')
  }

  openDeleteConfirmationModal(api: any): void {
    this.focusedApi = api
    this.isDeleteConfirmationModalOpen = true
  }

  async modalDeleteApi(api: ApiKey): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    await deleteTeamApiKey(authToken, api.id)
    this.isDeleteConfirmationModalOpen = false
    this.getApiKeys()
  }

  openNewApiKeyModal(): void {
    this.isNewApiKeyModalOpen = true
  }

  async getApiKeys(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.apiKeys = await getTeamApiKeys(authToken)
  }

  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('@/assets/animations/kea-loading.json'),
  }
}
