




































































































































import { Vue, Component } from 'vue-property-decorator'
import NavBar from '@/components/NavBar.vue'
import KeaButton from '@/components/KeaButton.vue'
import KeaAlert from '@/components/KeaAlert.vue'
import NavStorages from '@/components/NavStorages.vue'
import FormData from 'form-data'
import { StorageBucket, getStorage, uploadFiles } from '@/api/storage'

@Component({
  components: {
    NavBar,
    KeaButton,
    KeaAlert,
    NavStorages,
  },
})
export default class UploadFiles extends Vue {
  route = Number(this.$route.params.id)
  uploading = false
  errorMessage = ''
  successMessage = ''
  storage: StorageBucket = {
    id: 0,
    name: '',
  }

  async getStorage(): Promise<any> {
    const authToken = localStorage.getItem('authToken') || ''
    this.storage = await getStorage(this.route, authToken)
  }

  mounted(): void {
    this.getStorage()
  }

  async uploadFiles(event: any): Promise<any> {
    this.successMessage = ''
    this.errorMessage = ''
    this.uploading = true

    const files = event.target.files
    const authToken = localStorage.getItem('authToken') || ''

    const formData = new FormData()
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      formData.append('fileName', file)
    }
    if (formData != null) {
      await uploadFiles(this.storage.id, authToken, formData)
      this.uploading = false
      this.successMessage = 'Files uploaded successfully'
    } else {
      this.uploading = false
      this.errorMessage = 'Error uploading files'
    }
  }
  redirectToStorage(storage: StorageBucket): void {
    this.$router.push(`/storages/${storage.id}`)
  }

  cancelUpload(): void {
    if (this.storage.id != 0) this.$router.push(`/storages/${this.storage.id}`)
  }
}
