










































































































































































import { Vue, Component } from 'vue-property-decorator'
import NavBar from '@/components/NavBar.vue'
import KeaButton from '@/components/KeaButton.vue'
import KeaBadge from '@/components/KeaBadge.vue'
import KeaAlert from '@/components/KeaAlert.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import { Storage, StorageBucket, getStorages } from '@/api/storages'
import { deleteStorage } from '@/api/storage'
import NavStorages from '@/components/NavStorages.vue'
import FreeTier from '@/components/FreeTierComponent.vue'

@Component({
  components: {
    NavBar,
    KeaButton,
    KeaBadge,
    KeaAlert,
    ConfirmationModal,
    NavStorages,
    FreeTier,
  },
})
export default class Storages extends Vue {
  get subscribed(): boolean {
    return localStorage.getItem('paidUser') == 'true'
  }

  storages: Storage[] = []
  storagesFiles: StorageBucket = { id: 0, name: '', size: 0 }
  loading = true

  focusedStorage: Storage | null = null
  isDeleteConfirmationModalOpen = false
  pendingDelete = false

  async getStoragesBuckets(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.storages = await getStorages(authToken)
  }

  async newDatastore(): Promise<void> {
    this.$router.push('/storages/create')
  }

  async viewDetails(id: number): Promise<void> {
    this.$router.push(`/storages/${id}`)
  }

  mounted(): void {
    if (this.subscribed) {
      this.getStoragesBuckets()
    }
    setTimeout(() => {
      this.loading = false
    }, 500)
  }

  openDeleteConfirmationModal(storage: Storage): void {
    this.focusedStorage = storage
    this.isDeleteConfirmationModalOpen = true
  }

  async modalDeleteStorage(id: number): Promise<void> {
    this.pendingDelete = true
    const authToken = localStorage.getItem('authToken') || ''
    await deleteStorage(authToken, id)
    this.isDeleteConfirmationModalOpen = false
    this.getStoragesBuckets()
    this.pendingDelete = false
  }

  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('@/assets/animations/kea-loading.json'),
  }
}
