
















































































import { Vue, Component } from 'vue-property-decorator'
import NavBar from '@/components/NavBar.vue'
import KeaButton from '@/components/KeaButton.vue'
import KeaAlert from '@/components/KeaAlert.vue'
import NavStorages from '@/components/NavStorages.vue'
import { createStorageBucket } from '@/api/storage'

@Component({
  components: {
    NavBar,
    KeaButton,
    KeaAlert,
    NavStorages,
  },
})
export default class NewDatastore extends Vue {
  name = ''
  errorMessage = ''
  successMessage = ''

  authToken = localStorage.getItem('authToken') || ''

  validateName(name: string): boolean {
    const regex = /^(?=.{3,30}$)([a-z0-9]+(?:(?<=\w)-(?=\w))?)+$/
    if (!regex.test(name)) {
      return false
    }
    return true
  }

  async createStorage(): Promise<void> {
    this.errorMessage = ''
    this.successMessage = ''
    if (this.name == '') {
      this.errorMessage = 'Please enter a name for the storage.'
    } else {
      if (!this.validateName(this.name)) {
        this.errorMessage =
          'Storage names should follow these rules: they must be between 3 and 30 characters long, contain lowercase letters, numbers, and hyphens (-), and must begin and end with a letter or number.'
        return
      }
      await createStorageBucket(this.name, this.authToken)
        .then(() => {
          this.successMessage =
            'Your storage was created successfully. You can now add files to it.'
          setTimeout(() => {
            this.$router.push('/storages')
          }, 3000)
        })
        .catch(() => {
          this.errorMessage = 'Something went wrong. Try again.'
        })
    }
  }

  cancelNewDatastore(): void {
    this.$router.push('/storages')
  }
}
