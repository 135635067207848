import axios from 'axios'

export interface TeamMember {
  email: string
  first_name: string
  last_name: string
  role: string
  id: number
}

export interface PendingInvite {
  email: string
  id: number
}

export interface GitConnections {
  github: boolean
  bitbucket: boolean
}

export interface TeamDetail {
  name: string
  members: TeamMember[]
  pending_invites: PendingInvite[]
  git_connections: GitConnections[]
}

export async function getTeam(authToken: string): Promise<TeamDetail> {
  const response = await axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/teams`, {
      headers: { Authorization: authToken },
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return response.data
}

export const inviteTeamMember = async (
  authToken: string,
  email: string,
  buySeat: boolean
): Promise<any> => {
  const url =
    `${process.env.VUE_APP_KEA_API}/v1/teams/invite` +
    (buySeat ? '?buy-seat=true' : '')
  console.log(url)
  await axios
    .post(
      url,
      {
        email,
      },
      { headers: { Authorization: authToken } }
    )
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export const resendInviteTeamMember = async (
  authToken: string,
  id: number
): Promise<any> => {
  await axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/teams/invite/${id}/resend-email`,
      {},
      { headers: { Authorization: authToken } }
    )
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export const createTeam = async (
  authToken: string,
  name: string
): Promise<any> => {
  await axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/teams`,
      { name },
      { headers: { Authorization: authToken } }
    )
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export interface ValidateTeamInviteResult {
  email: string
}

export const validateTeamInvite = async (
  inviteID: string
): Promise<ValidateTeamInviteResult> => {
  const response = await axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/teams/invite/${inviteID}/validate`)
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return response.data
}

export const acceptTeamInvite = async (inviteID: string): Promise<void> => {
  await axios
    .post(`${process.env.VUE_APP_KEA_API}/v1/teams/invite/${inviteID}/accept`)
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export const removeTeamMember = async (
  authToken: string,
  memberID: number
): Promise<any> => {
  await axios
    .delete(`${process.env.VUE_APP_KEA_API}/v1/teams/members/${memberID}`, {
      headers: { Authorization: authToken },
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export interface ApiKey {
  id: number
  name: string
  creator_id: number
  team_id: number
  value: string
}

export async function getTeamApiKeys(authToken: string): Promise<ApiKey[]> {
  const response = await axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/api-keys`, {
      headers: { Authorization: authToken },
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return response.data
}

export async function createTeamApiKey(
  authToken: string,
  name: string
): Promise<ApiKey> {
  const response = await axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/api-keys`,
      { name },
      { headers: { Authorization: authToken } }
    )
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return response.data
}

export async function deleteTeamApiKey(
  authToken: string,
  ApiKeyID: number
): Promise<void> {
  await axios
    .delete(`${process.env.VUE_APP_KEA_API}/v1/api-keys/${ApiKeyID}`, {
      headers: { Authorization: authToken },
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}
