




import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class KeaBadge extends Vue {
  @Prop() readonly text!: string
  @Prop() readonly type!: string

  get color(): string {
    switch (this.type) {
      case 'success':
        return 'bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full'
      case 'error':
        return 'bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full'
      case 'warning':
        return 'bg-yellow-100 warning text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full'
      default:
        return 'bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full'
    }
  }
}
