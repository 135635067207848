

























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class KeaButton extends Vue {
  @Prop() readonly text!: string
  @Prop() readonly type!: string
  @Prop() readonly imgSrc!: string
  @Prop() readonly action!: (...args: unknown[]) => unknown

  get color(): string {
    switch (this.type) {
      case 'primary':
        return 'text-white bg-kea-blue-700 hover:bg-kea-blue-600 focus:ring-kea-blue-500'
      case 'secondary':
        return 'text-white bg-kea-orange-600 hover:bg-kea-orange-700 focus:ring-kea-orange-500'
      case 'outline':
        return 'bg-white text-kea-blue-700 hover:bg-gray-50 border-2 border-kea-blue-500 focus:ring-kea-blue-500'
      case 'onlytext':
        return 'text-kea-blue-700 focus:border-b-2 focus:border-kea-blue-500'
      case 'navbar':
        return 'bg-transparent font-regular text-sm hover:text-gray-600 py-2 rounded-md text-sm'
      default:
        return 'text-white bg-kea-blue-700 hover:bg-kea-blue-600 focus:ring-kea-blue-500'
    }
  }
}
