


















































































































import { Vue, Component } from 'vue-property-decorator'
import { createTeam } from '@/api/teams'

import NavBar from '@/components/NavBar.vue'
import KeaButton from '@/components/KeaButton.vue'
import KeaLogo from '@/components/KeaLogo.vue'

@Component({
  components: {
    NavBar,
    KeaButton,
    KeaLogo,
  },
})
export default class CreateTeam extends Vue {
  private name = ''
  private errorMessage = ''

  async createTeam(): Promise<void> {
    console.log('create team')
    const authToken = localStorage.getItem('authToken') || ''
    await createTeam(authToken, this.name)
      .then(() => {
        localStorage.setItem('completedSetup', 'missing-subscription')
        this.$router.push('/')
      })
      .catch((err) => {
        this.errorMessage = err.charAt(0).toUpperCase() + err.slice(1)
      })
  }
}
