































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class KeaTable extends Vue {
  @Prop() readonly header!: Array<string>
}
