





















































































































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ConfirmationModal extends Vue {
  @Prop() readonly title!: string
  @Prop() readonly message!: string
  @Prop() readonly confirmationText!: string

  close(): void {
    this.$emit('close')
  }
  confirm(): void {
    this.$emit('confirm')
  }
}
