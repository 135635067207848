import axios from 'axios'
import { InstanceType } from '@/api/catalog'

export interface Environment {
  id: number
  name: string
  public_name: string
  cloud_instance_id: string
  status: string
  instance_type: InstanceType
  password: string
}

export async function getEnvironments(
  authToken: string
): Promise<Environment[]> {
  const response = await axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/environments`, {
      headers: { Authorization: authToken },
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return response.data
}

export async function createEnvironment(
  authToken: string,
  imageName: string,
  instanceTypeName: string,
  name: string,
  repository: string,
  storage: string
): Promise<Environment> {
  const response = await axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/environments`,
      {
        name,
        image_name: imageName,
        instance_type_name: instanceTypeName,
        git_repository: repository,
        storage_name: storage,
      },
      {
        headers: { Authorization: authToken },
      }
    )
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return response.data
}

export async function shutdownEnvironment(
  authToken: string,
  id: number
): Promise<boolean> {
  await axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/environments/${id}/shutdown`,
      {},
      { headers: { Authorization: authToken } }
    )
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return true
}

export async function deleteEnvironment(
  authToken: string,
  id: number
): Promise<boolean> {
  await axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/environments/${id}/delete`,
      {},
      { headers: { Authorization: authToken } }
    )
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return true
}

export async function startEnvironment(
  authToken: string,
  id: number
): Promise<boolean> {
  await axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/environments/${id}/start`,
      {},
      { headers: { Authorization: authToken } }
    )
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return true
}

export async function restartEnvironment(
  authToken: string,
  id: number
): Promise<boolean> {
  await axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/environments/${id}/restart`,
      {},
      { headers: { Authorization: authToken } }
    )
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return true
}
