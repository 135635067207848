
























































































































































































































import { Vue, Component } from 'vue-property-decorator'
import NavBar from '@/components/NavBar.vue'
import KeaSelector from '@/components/KeaSelector.vue'
import {
  getCharges,
  ChargeInfo,
  BillingLimits,
  getBillingLimits,
  setBillingLimits,
  updateBillingLimits,
  deleteBillingLimits,
} from '@/api/billing'
import KeaTooltip from '@/components/KeaTooltip.vue'
import KeaButton from '@/components/KeaButton.vue'
import KeaAlert from '@/components/KeaAlert.vue'

@Component({
  components: {
    NavBar,
    KeaSelector,
    KeaTooltip,
    KeaButton,
    KeaAlert,
  },
})
export default class Billing extends Vue {
  billing: ChargeInfo = {
    from_date: '',
    to_date: '',
    total_cost: 0.0,
  }
  billingLimits: BillingLimits | null = null

  selectedMonth = false
  options: string[] = []
  selectedOption = ''
  currentMonth = ''

  newAlertLimit = 0
  newMaxLimit = 0
  errorMessage = ''
  successMessage = ''

  mounted(): void {
    this.options = this.getRecentMonths()
    this.initialCharge()
    this.getBillingLimits()
  }

  async initialCharge(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.currentMonth = this.options[this.options.length - 1]
    const { from_date, to_date } = this.getMonthRange(this.currentMonth)
    this.billing = await getCharges(authToken, from_date, to_date)
  }

  getRecentMonths(): string[] {
    const today = new Date()
    const thisMonth = today.getMonth()
    const thisYear = today.getFullYear()
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const recentMonths = []
    for (let i = thisMonth; i >= 0; i--) {
      recentMonths.unshift(`${months[i]} ${thisYear}`)
    }
    return recentMonths
  }

  getMonthRange(monthString: string): {
    from_date: string
    to_date: string
  } {
    const [month, year] = monthString.split(' ')
    const fromDate = new Date(`${month} 1, ${year}`)
    const toDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0)

    fromDate.setUTCHours(0, 0, 0)
    toDate.setUTCHours(23, 59, 59)

    return {
      from_date: fromDate.toISOString(),
      to_date: toDate.toISOString(),
    }
  }

  async onOptionSelected(selectedOption: string): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.selectedOption = selectedOption
    const { from_date, to_date } = this.getMonthRange(selectedOption)
    this.selectedMonth = true
    this.billing = await getCharges(authToken, from_date, to_date)
  }

  async getBillingLimits(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.billingLimits = await getBillingLimits(authToken)
  }

  validateLimits(): boolean {
    if (this.newAlertLimit > this.newMaxLimit) {
      this.errorMessage = 'Alert limit cannot be greater than max limit'
      return false
    }
    if (this.newAlertLimit <= 0 || this.newMaxLimit <= 0) {
      this.errorMessage = 'Limits cannot be zero or negative'
      return false
    }
    return true
  }

  async setBillingLimits(): Promise<void> {
    this.successMessage = ''
    this.errorMessage = ''
    const authToken = localStorage.getItem('authToken') || ''

    if (this.validateLimits()) {
      await setBillingLimits(
        authToken,
        this.newAlertLimit,
        this.newMaxLimit
      ).then(() => {
        this.billingLimits = {
          alert_amount: this.newAlertLimit,
          max_amount: this.newMaxLimit,
        }
        this.successMessage = 'Limits set successfully'
        this.newAlertLimit = 0
        this.newMaxLimit = 0
      })
    }
  }

  async updateBillingLimits(): Promise<void> {
    this.successMessage = ''
    this.errorMessage = ''
    const authToken = localStorage.getItem('authToken') || ''

    if (this.validateLimits()) {
      await updateBillingLimits(
        authToken,
        this.newAlertLimit,
        this.newMaxLimit
      ).then(() => {
        this.billingLimits = {
          alert_amount: this.newAlertLimit,
          max_amount: this.newMaxLimit,
        }
        this.successMessage = 'Limits updated successfully'
        this.newAlertLimit = 0
        this.newMaxLimit = 0
      })
    }
  }

  async deleteBillingLimits(): Promise<void> {
    this.successMessage = ''
    this.errorMessage = ''
    const authToken = localStorage.getItem('authToken') || ''
    await deleteBillingLimits(authToken).then(() => {
      this.billingLimits = null
      this.successMessage = 'Limits deleted successfully'
    })
  }
}
