




































































import { confirmUser } from '@/api/authentication'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class ConfirmAccount extends Vue {
  errorMessage = ''
  successMessage = ''
  confirming = true
  created() {
    this.confirmAccount()
  }

  async confirmAccount(): Promise<void> {
    const verificationToken = this.$route.query.token
    await confirmUser(verificationToken)
      .then(() => {
        this.confirming = false
        this.successMessage =
          'Your account was successfully confirmed. You can go to login page to access your account.'
      })
      .catch(() => {
        this.confirming = false
        this.errorMessage = 'Something went wrong. Please try again.'
      })
  }
}
