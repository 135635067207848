import axios from 'axios'

export const registerGitHub = async (
  code: string | (string | null)[],
  authToken: string
): Promise<void> => {
  await axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/oauth/git/register/github`,
      {
        authorization_code: code,
      },
      { headers: { Authorization: authToken } }
    )
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export const unregisterGit = async (
  provider: string,
  authToken: string
): Promise<void> => {
  await axios
    .delete(
      `${process.env.VUE_APP_KEA_API}/v1/oauth/git/unregister/${provider}`,
      {
        headers: { Authorization: authToken },
      }
    )
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export const registerBitBucket = async (
  code: string | (string | null)[],
  authToken: string
): Promise<void> => {
  const response = await axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/oauth/git/register/bitbucket`,
      {
        authorization_code: code,
      },
      { headers: { Authorization: authToken } }
    )
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export interface GitRepository {
  name: string
  full_name: string
  clone_url: string
}

export async function getAllRepositories(
  authToken: string
): Promise<GitRepository[]> {
  const response = await axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/teams/git/repositories`, {
      headers: { Authorization: authToken },
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
  return response.data
}
