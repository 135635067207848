




























































































































































































































import { Vue, Component } from 'vue-property-decorator'
import NavBar from '@/components/NavBar.vue'
import { Image, Library, getImages, getImage } from '@/api/images'
import KeaAccordion from '@/components/KeaAccordion.vue'
import KeaTable from '@/components/KeaTable.vue'
import KeaButton from '@/components/KeaButton.vue'
import FreeTier from '@/components/FreeTierComponent.vue'

@Component({
  components: {
    NavBar,
    KeaAccordion,
    KeaTable,
    KeaButton,
    FreeTier,
  },
})
export default class Images extends Vue {
  get subscribed(): boolean {
    return localStorage.getItem('paidUser') == 'true'
  }
  loading = true
  loadingLibraries = true
  openAccordionId: number | null = null

  images: Image[] = []
  selectedLibraries: Library[] = []
  currentPage = 1
  librariesPerPage = 10
  startIndex = 1
  endIndex = 10

  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('@/assets/animations/kea-loading.json'),
  }

  async getImageCatalog(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.images = await getImages(authToken)
  }

  mounted(): void {
    if (this.subscribed) {
      this.getImageCatalog()
    }
    setTimeout(() => {
        this.loading = false
    }, 500)
  }

  handleCardClick(imageItem: Image): void {
    if (this.openAccordionId != imageItem.id) {
      this.currentPage = 1
      this.startIndex = 1
      this.getImageClick(imageItem.id)
    }
  }

  async getImageClick(imageId: number): Promise<void> {
    this.loadingLibraries = true
    this.openAccordionId = null
    this.selectedLibraries = []
    const authToken = localStorage.getItem('authToken') || ''
    const selectedImage = await getImage(authToken, imageId)
    this.openAccordionId = imageId
    this.selectedLibraries = selectedImage.libraries
    setTimeout(() => {
      this.loadingLibraries = false
    }, 200)
  }

  deleteImage(): void {
    console.log('delete image')
  }

  newImage(): void {
    console.log('new image')
  }

  paginatedLibraries(): Library[] {
    const startIndex = (this.currentPage - 1) * this.librariesPerPage
    const endIndex = startIndex + this.librariesPerPage
    this.startIndex = startIndex + 1

    if (endIndex > this.selectedLibraries.length) {
      this.endIndex = this.selectedLibraries.length
    } else {
      this.endIndex = endIndex
    }

    return this.selectedLibraries.slice(startIndex, endIndex)
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--
    }
  }

  nextPage(): void {
    const totalPages = Math.ceil(
      this.selectedLibraries.length / this.librariesPerPage
    )
    if (this.currentPage < totalPages) {
      this.currentPage++
    }
  }
}
