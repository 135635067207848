import axios from 'axios'

export interface Library {
  id: number
  name: string
  version: string
}
export interface Image {
  id: number
  name: string
  language: string
  team_id: number | null
  libraries: Library[]
}

export async function getImages(authToken: string): Promise<Image[]> {
  const response = await axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/images`, {
      headers: { Authorization: authToken },
    })
    .catch((err) => {
      return Promise.reject(err.response.data.error)
    })

  return response.data
}

export async function getImage(
  authToken: string,
  imageId: number
): Promise<Image> {
  const response = await axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/images/${imageId}`, {
      headers: { Authorization: authToken },
    })
    .catch((err) => {
      return Promise.reject(err.response.data.error)
    })

  return response.data
}
