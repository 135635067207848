
















import { Vue, Component, Prop } from 'vue-property-decorator'
import KeaButton from '@/components/KeaButton.vue'

@Component({
  components: {
    KeaButton,
  },
})
export default class FreeTier extends Vue {
  @Prop() readonly feature!: string
}
