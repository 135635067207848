




















































































































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class InstructionsModal extends Vue {
  @Prop() password!: string
  @Prop() public_name!: string

  close(): void {
    this.$emit('close')
  }
}
