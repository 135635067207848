





















































































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import NavBar from '@/components/NavBar.vue'
import KeaButton from '@/components/KeaButton.vue'
import KeaBadge from '@/components/KeaBadge.vue'
import KeaAlert from '@/components/KeaAlert.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import { Environment } from '@/api/environments'

import {
  getTeam,
  inviteTeamMember,
  TeamDetail,
  resendInviteTeamMember,
  removeTeamMember,
} from '@/api/teams'
import { CurrentUser, getCurrentUser } from '@/api/authentication'

@Component({
  components: {
    NavBar,
    KeaButton,
    KeaBadge,
    KeaAlert,
    ConfirmationModal,
  },
})
export default class Team extends Vue {
  environments: Environment[] = []
  team: TeamDetail = {
    name: '',
    members: [],
    pending_invites: [],
    git_connections: [],
  }
  email = ''
  successMessage = ''
  errorMessage = ''
  expired = false
  currentUser: CurrentUser = {
    email: '',
    first_name: '',
    last_name: '',
    role: '',
  }

  loading = true

  get isInviteNewUserModalOpen() {
    return this.errorMessage === 'You have reached the maximum number of seats'
  }

  currentUserIsAdmin(): boolean {
    return this.currentUser.role === 'admin'
  }

  async getTeamMembers(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.team = await getTeam(authToken)
  }

  async getCurrentUser(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.currentUser = await getCurrentUser(authToken)
  }

  mounted(): void {
    this.getCurrentUser()
    this.getTeamMembers()
    setTimeout(() => {
      this.loading = false
    }, 500)
  }

  async buySeatAndInviteTeamMember(): Promise<void> {
    this.errorMessage = ''
    this.successMessage = ''
    const authToken = localStorage.getItem('authToken') || ''
    if (this.email === '') {
      this.errorMessage = 'Please enter a valid email address'
      return
    }
    await inviteTeamMember(authToken, this.email, true)
      .then(() => {
        this.getTeamMembers()
        this.email = ''
        this.successMessage = 'Invitation sent'
      })
      .catch((err) => {
        this.errorMessage = err.charAt(0).toUpperCase() + err.slice(1)
      })
  }

  async inviteTeamMember(): Promise<void> {
    this.errorMessage = ''
    this.successMessage = ''
    const authToken = localStorage.getItem('authToken') || ''
    if (this.email === '') {
      this.errorMessage = 'Please enter a valid email address'
      return
    }
    await inviteTeamMember(authToken, this.email, false)
      .then(() => {
        this.getTeamMembers()
        this.email = ''
        this.successMessage = 'Invitation sent'
      })
      .catch((err) => {
        this.errorMessage = err.charAt(0).toUpperCase() + err.slice(1)
      })
  }

  async resendInviteTeamMember(id: number): Promise<void> {
    this.errorMessage = ''
    this.successMessage = ''
    const authToken = localStorage.getItem('authToken') || ''
    await resendInviteTeamMember(authToken, id)
      .then(() => {
        this.successMessage = 'Invitation resent'
      })
      .catch((err) => {
        this.errorMessage = err.charAt(0).toUpperCase() + err.slice(1)
      })
  }

  async removeTeamMember(memberID: number): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    await removeTeamMember(authToken, memberID)
      .then(() => {
        this.successMessage = 'Member removed'
      })
      .catch((err) => {
        this.errorMessage = err.charAt(0).toUpperCase() + err.slice(1)
      })
  }

  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('@/assets/animations/kea-loading.json'),
  }
}
