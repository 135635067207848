






















































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class KeaAccordion extends Vue {
  @Prop() openable!: boolean
  @Prop() alwaysOpen!: boolean

  isOpen = false

  mounted(): void {
    if (this.alwaysOpen) {
      this.isOpen = true
    }
  }

  toggleAccordion(): void {
    if (!this.openable && !this.alwaysOpen) {
      this.isOpen = !this.isOpen
    }
  }
}
