































































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class NavStorages extends Vue {
  @Prop() storageName!: string
  @Prop() storageId!: number
  @Prop() fileName!: string

  hasStorage(): boolean {
    return this.storageName != undefined
  }

  hasFile(): boolean {
    return this.fileName != undefined
  }

  redirectToStorages(): void {
    let path = this.$route.path
    if (path != '/storages') {
      this.$router.push(`/storages/`)
    }
  }

  redirectToStorageId(): void {
    if (this.storageId != undefined)
      this.$router.push(`/storages/${this.storageId}`)
  }
}
