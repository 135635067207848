import axios from 'axios'

export interface LoginResult {
  token: string
  completed_setup: string
  subscription_type: string
}

export function login(email: string, password: string): Promise<LoginResult> {
  return axios
    .post(`${process.env.VUE_APP_KEA_API}/v1/users/login`, {
      email: email,
      password: password,
    })
    .then((response) => {
      return {
        token: response.data.token,
        completed_setup: response.data.completed_setup,
        subscription_type: response.data.subscription_type,
      }
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export interface RegisterParams {
  first_name: string
  last_name: string
  email: string
  password: string
  newsletter_opt_in: boolean
  is_invited: boolean
}

export interface User {
  ID: string
  FirstName: string
  LastName: string
  Email: string
}

export async function register(params: RegisterParams): Promise<any> {
  await axios
    .post(`${process.env.VUE_APP_KEA_API}/v1/users`, params)
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export async function confirmUser(
  verificationToken: string | (string | null)[]
): Promise<any> {
  return await axios.post(
    `${process.env.VUE_APP_KEA_API}/v1/users/confirm-account`,
    {
      token: verificationToken,
    }
  )
}

export const logout = async (authToken: string): Promise<void> => {
  await axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/users/logout`,
      {},
      {
        headers: { Authorization: authToken },
      }
    )
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export interface CurrentUser {
  email: string
  first_name: string
  last_name: string
  role: string
}

export const getCurrentUser = async (
  authToken: string
): Promise<CurrentUser> => {
  return axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/users/current`, {
      headers: { Authorization: authToken },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export interface PasswordReset {
  email: string
  UniqueID: string
  successMessage: string
}

export async function passwordReset(email: string): Promise<any> {
  return axios
    .post(`${process.env.VUE_APP_KEA_API}/v1/users/reset-password`, {
      email: email,
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export async function passwordResetClaim(
  password: string,
  uniqueID: string
): Promise<any> {
  return axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/users/reset-password/${uniqueID}/claim`,
      {
        password,
      }
    )

    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export async function check(authToken: string) {
  const response = await axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/users/check`, {
      headers: { Authorization: authToken },
    })
    .catch((err) => {
      return Promise.reject(err.response.data.error)
    })

  return response.data
}
