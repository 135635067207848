












































import { Vue, Prop, Component } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class KeaTooltip extends Vue {
  @Prop() readonly text!: string

  isHidden = false

  showTooltip(): void {
    this.isHidden = true
  }

  hideTooltip(): void {
    this.isHidden = false
  }
}
