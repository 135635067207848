import axios from 'axios'

export interface ChargeInfo {
  total_cost: number
  from_date: string
  to_date: string
}

export const getCharges = async (
  authToken: string,
  from_date: string,
  to_date: string
): Promise<ChargeInfo> => {
  return axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/charges`, {
      headers: { Authorization: authToken },
      params: { from_date, to_date },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export interface BillingLimits {
  alert_amount: number
  max_amount: number
}

export const getBillingLimits = async (
  authToken: string
): Promise<BillingLimits> => {
  return axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/billing/limits`, {
      headers: { Authorization: authToken },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export const setBillingLimits = async (
  authToken: string,
  alert_amount: number,
  max_amount: number
): Promise<BillingLimits> => {
  return axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/billing/limits`,
      {
        alert_amount,
        max_amount,
      },
      { headers: { Authorization: authToken } }
    )
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export const updateBillingLimits = async (
  authToken: string,
  alert_amount: number,
  max_amount: number
): Promise<BillingLimits> => {
  return axios
    .put(
      `${process.env.VUE_APP_KEA_API}/v1/billing/limits`,
      {
        alert_amount,
        max_amount,
      },
      { headers: { Authorization: authToken } }
    )
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export const deleteBillingLimits = async (
  authToken: string
): Promise<BillingLimits> => {
  return axios
    .delete(`${process.env.VUE_APP_KEA_API}/v1/billing/limits`, {
      headers: { Authorization: authToken },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export const createFreeSubscription = async (authToken: string) => {
  return axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/teams/free-subscription`,
      {},
      { headers: { Authorization: authToken } }
    )
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}
