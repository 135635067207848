























































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import { logout } from '@/api/authentication'
import { CurrentUser, getCurrentUser } from '@/api/authentication'
import KeaButton from './KeaButton.vue'
import KeaNavButton from './KeaNavButton.vue'
import KeaLogo from './KeaLogo.vue'

@Component({
  components: {
    KeaButton,
    KeaNavButton,
    KeaLogo,
  },
})
export default class NavBar extends Vue {
  get subscribed(): boolean {
    return localStorage.getItem('paidUser') == 'true'
  }

  active = false
  showOptions = false
  currentUser: CurrentUser = {
    email: '',
    first_name: '',
    last_name: '',
    role: '',
  }

  mounted(): void {
    this.getCurrentUser()
  }

  async getCurrentUser(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.currentUser = await getCurrentUser(authToken)
  }

  linksHandler(): void {
    this.active = !this.active
  }
  redirectToSettings(): void {
    let path = this.$route.path
    if (path != '/settings') {
      this.$router.push('/settings')
    }
  }
  redirectToImages(): void {
    let path = this.$route.path
    if (path != '/images') {
      this.$router.push('/images')
    }
  }
  redirectToNewEnv(): void {
    let path = this.$route.path
    if (path != '/new-environment') {
      this.$router.push('/new-environment')
    }
  }
  redirectToDashboard(): void {
    let path = this.$route.path
    if (path != '/') {
      this.$router.push('/')
    }
  }
  redirectToStorages(): void {
    let path = this.$route.path
    if (path != '/storages') {
      this.$router.push('/storages')
    }
  }
  redirectToTeamManagement(): void {
    let path = this.$route.path
    if (path != '/team') {
      this.$router.push('/team')
    }
  }
  redirectToBilling(): void {
    let path = this.$route.path
    if (path != '/billing') {
      this.$router.push('/billing')
    }
  }

  redirectToModels(): void {
    let path = this.$route.path
    if (path != '/models') {
      this.$router.push('/models')
    }
  }

  async logout(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    await logout(authToken).then(() => {
      localStorage.removeItem('authToken')
      localStorage.removeItem('completedSetup')
      localStorage.removeItem('paidUser')
      this.$router.push('/login')
    })
  }
  toggleOptions(): void {
    this.showOptions = !this.showOptions
  }
  hideOptions(): void {
    this.showOptions = false
  }
}
