









import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class KeaLogo extends Vue {
  @Prop() readonly keastyle!: string

  redirectToDashboard(): void {
    let path = this.$route.path
    if (path != '/') {
      this.$router.push('/')
    }
  }
}
