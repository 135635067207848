










import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class KeaNavButton extends Vue {
  @Prop() readonly text!: string
  @Prop() readonly action!: (...args: unknown[]) => unknown

  get border(): string {
    if (
      this.text === 'Storages' ||
      this.text === 'Dashboard' ||
      this.text === 'Images' ||
      this.text === 'Model Deployments'
    ) {
      return 'bg-transparent font-regular text-sm hover:text-gray-600 py-2 rounded-md text-sm'
    } else {
      return 'bg-kea-blue-700 hover:bg-blue-600 text-white font-regular px-3 text-sm hover:text-white border border-blue-500 hover:border-transparent rounded-md'
    }
  }
}
