











































































































































































































import { Vue, Component } from 'vue-property-decorator'

import NavBar from '@/components/NavBar.vue'
import { getModel, Model, Deployment } from '@/api/models'
import KeaTable from '@/components/KeaTable.vue'
import KeaBadge from '@/components/KeaBadge.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

@Component({
  components: {
    NavBar,
    KeaTable,
    KeaBadge,
    ConfirmationModal,
  },
})
export default class ModelDetails extends Vue {
  private interval: any
  loading = true

  model!: Model

  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('@/assets/animations/kea-loading.json'),
  }

  async getModel(): Promise<void> {
    const modelId = this.$route.params.id
    const authToken = localStorage.getItem('authToken') || ''
    this.model = await getModel(authToken, modelId)
    if (this.loading) {
      this.loading = false
    }
    this.interval = setInterval(async () => {
      this.model = await getModel(authToken, modelId)
    }, 5_000)
  }

  mounted(): void {
    this.getModel()
  }

  beforeDestroy(to: any, from: any, next: () => void): void {
    clearInterval(this.interval)
  }

  getType(status: string): string {
    switch (status) {
      case 'building':
        return 'warning'
      case 'ready':
        return 'success'
      case 'error':
        return 'error'
      default:
        return 'success'
    }
  }

  deploymentURL(uniqueId: string): string {
    return `${process.env.VUE_APP_KEA_API}/models/${uniqueId}/predict`
  }
}
