import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import Dashboard from '@/views/Dashboard.vue'
import Team from '@/views/Team.vue'
import NewEnvironment from '@/views/NewEnvironment.vue'
import CreateTeam from '@/views/CreateTeam.vue'
import Subscribe from '@/views/Subscribe.vue'
import AcceptInvite from '@/views/AcceptInvite.vue'
import GithubCallback from '@/views/GithubCallback.vue'
import Settings from '@/views/Settings.vue'
import PasswordReset from '@/views/PasswordReset.vue'
import Images from '@/views/Images.vue'
import ConfirmAccount from '@/views/ConfirmAccount.vue'
import BitbucketCallback from '@/views/BitbucketCallback.vue'
import Storages from '@/views/Storages.vue'
import NewDatastore from '@/views/NewDatastore.vue'
import Storage from '@/views/Storage.vue'
import UploadFiles from '@/views/UploadFiles.vue'
import Billing from '@/views/Billing.vue'
import Models from '@/views/Models.vue'
import ModelDetails from '@/views/Model.vue'

import { check } from '@/api/authentication'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { onlyIncognito: true },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { onlyIncognito: true },
  },
  {
    path: '/password-reset',
    name: 'Password Reset',
    component: PasswordReset,
    meta: { onlyIncognito: true },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuthCompletedSetup: true },
  },
  {
    path: '/images',
    name: 'Images',
    component: Images,
    meta: { requiresAuthCompletedSetup: true },
  },
  {
    path: '/new-environment',
    name: 'NewEnvironment',
    component: NewEnvironment,
    meta: { requiresAuthCompletedSetup: true },
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
    meta: { requiresAuthCompletedSetup: true },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuthCompletedSetup: true },
  },
  {
    path: '/create-team',
    name: 'CreateTeam',
    component: CreateTeam,
    meta: { requiresAuth: true },
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: Subscribe,
    meta: { requiresAuth: true },
  },
  {
    path: '/accept-invite',
    name: 'Invite',
    component: AcceptInvite,
    meta: { onlyIncognito: true },
  },
  {
    path: '/oauth/github/callback',
    name: 'GitHubCallback',
    component: GithubCallback,
    meta: { requiresAuth: true },
  },
  {
    path: '/oauth/bitbucket/callback',
    name: 'BitbucketCallback',
    component: BitbucketCallback,
    meta: { requiresAuth: true },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: PasswordReset,
    meta: { onlyIncognito: true },
  },
  {
    path: '/confirm-account',
    name: 'ConfirmAccount',
    component: ConfirmAccount,
    meta: { onlyIncognito: true },
  },
  {
    path: '/storages',
    name: 'Storages',
    component: Storages,
    meta: { requiresAuth: true },
  },
  {
    path: '/storages/create',
    name: 'NewDatastore',
    component: NewDatastore,
    meta: { requiresAuth: true },
  },
  {
    path: '/storages/:id',
    name: 'Storage',
    component: Storage,
    meta: { requiresAuth: true },
  },
  {
    path: '/storages/:id/upload',
    name: 'UploadFiles',
    component: UploadFiles,
    meta: { requiresAuth: true },
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing,
    meta: { requiresAuth: true },
  },
  /*   {
    path: '/models',
    name: 'Models',
    component: Models,
    meta: { requiresAuth: true },
  }, */
  {
    path: '/models/:id',
    name: 'ModelDetails',
    component: ModelDetails,
    meta: { requiresAuth: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!localStorage.getItem('authToken')) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }

  if (to.matched.some((record) => record.meta.requiresAuthCompletedSetup)) {
    const authToken = localStorage.getItem('authToken') || ''
    // const jwtPayload = JSON.parse(window.atob(authToken.split('.')[1]))
    // const isExpired = Date.now() >= jwtPayload.exp * 1000
    let isInvalid = false
    await check(authToken).catch(() => {
      isInvalid = true
    })
    if (isInvalid) {
      localStorage.removeItem('authToken')
      localStorage.removeItem('completedSetup')
      localStorage.removeItem('paidUser')
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    } else if (localStorage.getItem('completedSetup') === 'missing-team') {
      next({ path: '/create-team', query: { redirect: to.fullPath } })
    } else if (
      localStorage.getItem('completedSetup') === 'missing-subscription'
    ) {
      next({ path: '/subscribe', query: { redirect: to.fullPath } })
    } else {
      next()
    }
  } else {
    next()
  }

  if (to.matched.some((record) => record.meta.onlyIncognito)) {
    if (localStorage.getItem('authToken')) {
      next({
        path: '/',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
