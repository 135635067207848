


























































































































































































































































































import { Vue, Component } from 'vue-property-decorator'

import { Image, getImages } from '@/api/images'
import { InstanceType, getCatalog } from '@/api/catalog'
import { createEnvironment } from '@/api/environments'
import { GitRepository, getAllRepositories } from '@/api/oauth'
import { Storage, getStorages } from '@/api/storages'

import NavBar from '@/components/NavBar.vue'
import KeaButton from '@/components/KeaButton.vue'
import KeaSearchSelect from '@/components/KeaSearchSelect.vue'
import KeaStorageSelect from '@/components/KeaStorageSelect.vue'
import KeaTooltip from '@/components/KeaTooltip.vue'
import FreeTier from '@/components/FreeTierComponent.vue'

@Component({
  components: {
    NavBar,
    KeaButton,
    KeaSearchSelect,
    KeaStorageSelect,
    KeaTooltip,
    FreeTier,
  },
})
export default class NewEnvironment extends Vue {
  get subscribed(): boolean {
    return localStorage.getItem('paidUser') == 'true'
  }

  private isLoading = false
  errorMessage = ''
  images: Image[] = []
  hardwareCatalog: InstanceType[] = []
  active = 0
  active2 = ''
  private selectedImage: string[] = []
  private selectedHardware: string[] = []
  name = ''
  private showHardware = true
  private showImages = true
  private showGithubRepository = true
  showName = true
  repositories: GitRepository[] = []
  storages: Storage[] = []
  selectedStorage = ''
  selectedRepository = ''

  loading = true

  handleStorageSelection(payload: any) {
    this.selectedStorage = payload
  }

  handleRepositorySelection(payload: any) {
    this.selectedRepository = payload
  }

  createEnvironment(): void {
    if (!this.isLoading) {
      this.isLoading = true
      const authToken = localStorage.getItem('authToken') || ''
      createEnvironment(
        authToken,
        this.selectedImage[0],
        this.selectedHardware[0],
        this.name,
        this.selectedRepository,
        this.selectedStorage
      )
        .then(() => {
          this.isLoading = false
          this.$router.push('/')
        })
        .catch((err) => {
          this.isLoading = false
          this.errorMessage = err.charAt(0).toUpperCase() + err.slice(1)
        })
    }
  }

  imageClick(id: number, name: string): void {
    this.selectedImage = [name]
    this.active = id
  }

  hardwareClick(e: string): void {
    this.selectedHardware = [e]
    this.active2 = e
  }

  async getHardwareCatalog(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.hardwareCatalog = await getCatalog(authToken)
  }

  async getImageCatalog(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.images = await getImages(authToken)
  }

  toggleHardware(): void {
    this.showHardware = !this.showHardware
  }

  toggleImages(): void {
    this.showImages = !this.showImages
  }

  toggleGithubRepository(): void {
    this.showGithubRepository = !this.showGithubRepository
  }

  mounted(): void {
    if (this.subscribed) {
      this.getHardwareCatalog()
      this.getImageCatalog()
      this.getRepositories()
      this.getStorages()
    }
    setTimeout(() => {
      this.loading = false
    }, 500)
  }
  async getRepositories(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.repositories = await getAllRepositories(authToken)
  }

  async getStorages(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.storages = await getStorages(authToken)
  }

  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('@/assets/animations/kea-loading.json'),
  }
}
