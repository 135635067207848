













































































































































import { Vue, Component } from 'vue-property-decorator'
import { login } from '@/api/authentication'
import KeaButton from '@/components/KeaButton.vue'
import KeaAlert from '@/components/KeaAlert.vue'

@Component({
  components: {
    KeaButton,
    KeaAlert,
  },
})
export default class Login extends Vue {
  email = ''
  password = ''
  errorMessage = ''
  emptyEmail = false
  emptyPassword = false

  login(): void {
    if (this.email === '') {
      this.emptyEmail = true
    }
    if (this.password === '') {
      this.emptyPassword = true
    }
    if (this.email !== '' && this.password !== '') {
      login(this.email, this.password)
        .then((r) => {
          localStorage.setItem('authToken', `Bearer ${r.token}`)
          localStorage.setItem('completedSetup', r.completed_setup)

          if (r.subscription_type === 'paid') {
            localStorage.setItem('paidUser', 'true')
          }

          if (r.completed_setup === 'missing-team') {
            this.$router.push('/create-team')
          } else {
            this.$router.push('/')
          }
        })
        .catch(() => {
          this.errorMessage = 'Invalid credentials'
        })
    }
  }
}
