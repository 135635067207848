





































































































































import { Vue, Component } from 'vue-property-decorator'
import { getCurrentUser } from '@/api/authentication'
import { createFreeSubscription } from '@/api/billing'

import KeaButton from '@/components/KeaButton.vue'
import KeaLogo from '@/components/KeaLogo.vue'

@Component({
  components: {
    KeaButton,
    KeaLogo,
  },
})
export default class Subscribe extends Vue {
  private email = ''

  mounted(): void {
    this.getCurrentUser()
  }

  async getCurrentUser(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    const currentUser = await getCurrentUser(authToken)
    this.email = currentUser.email
  }

  async freeSubscription(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    await createFreeSubscription(authToken)
    localStorage.setItem('completedSetup', 'active-subscription')
    this.$router.push('/')
  }

  proSubscription(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    window.Paddle.Checkout.open({
      product: process.env.VUE_APP_PADDLE_PRO_PLAN_ID,
      email: this.email,
      successCallback: function () {
        localStorage.setItem('completedSetup', 'active-subscription')
        that.$router.push('/')
      },
      closeCallback: function () {
        alert('Failed to checkout')
      },
    })
  }

  scaleSubscription(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    window.Paddle.Checkout.open({
      product: process.env.VUE_APP_PADDLE_SCALE_PLAN_ID,
      email: this.email,
      successCallback: function () {
        localStorage.setItem('completedSetup', 'active-subscription')
        that.$router.push('/')
      },
      closeCallback: function () {
        alert('Failed to checkout')
      },
    })
  }
}
