






































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import {
  Environment,
  getEnvironments,
  shutdownEnvironment,
  deleteEnvironment,
  startEnvironment,
  restartEnvironment,
} from '@/api/environments'
import { Model, Deployment, getModels } from '@/api/models'
import NavBar from '@/components/NavBar.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import InstructionsModal from '@/components/InstructionsModal.vue'
import KeaBadge from '@/components/KeaBadge.vue'

@Component({
  components: {
    NavBar,
    ConfirmationModal,
    InstructionsModal,
    KeaBadge,
  },
})
export default class Dashboard extends Vue {
  get subscribed(): boolean {
    return localStorage.getItem('paidUser') == 'true'
  }
  environments: Environment[] = []
  private stats = [
    { name: 'Resources created', stat: 0 },
    { name: 'Total hours of use', stat: 63 },
    { name: 'Total spending', stat: '$320' },
  ]
  private interval: any
  isDeleteConfirmationModalOpen = false
  isShutdownOrStartConfirmationModalOpen = false
  isRestartConfirmationModalOpen = false
  isInstructionsModalOpen = false

  focusedEnvironment: Environment | null = null

  loading = true

  copied = -1

  models: Model[] = []
  deployments: Deployment[] = []

  focusedModel: Model | null = null

  openDeleteConfirmationModal(environment: Environment): void {
    this.focusedEnvironment = environment
    this.isDeleteConfirmationModalOpen = true
  }

  openShutdownOrStartConfirmationModal(environment: Environment): void {
    this.focusedEnvironment = environment
    this.isShutdownOrStartConfirmationModalOpen = true
  }

  openRestartConfirmationModal(environment: Environment): void {
    this.focusedEnvironment = environment
    this.isRestartConfirmationModalOpen = true
  }

  openInstructionsModal(environment: Environment): void {
    this.focusedEnvironment = environment
    this.isInstructionsModalOpen = true
  }

  async getEnvironments(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    if (this.subscribed) {
      this.environments = await getEnvironments(authToken)
      this.interval = setInterval(async () => {
        this.environments = await getEnvironments(authToken)
      }, 5_000)
    }
  }

  created(): void {
    this.getEnvironments()
    this.getModels()
    setTimeout(() => {
      this.loading = false
    }, 500)
  }

  beforeUpdate(): void {
    this.controlAuthentication()
  }

  controlAuthentication(): void {
    const authToken = localStorage.getItem('authToken') || ''
    const jwtPayload = JSON.parse(window.atob(authToken.split('.')[1]))
    const tokenIsExpired = Date.now() >= jwtPayload.exp * 1000
    if (tokenIsExpired) {
      localStorage.removeItem('authToken')
      localStorage.removeItem('completedSetup')
      localStorage.removeItem('paidUser')
      this.$router.push('/login')
    }
  }

  beforeDestroy(to: any, from: any, next: () => void): void {
    clearInterval(this.interval)
  }

  shutdownEnvironment(id: number): void {
    const authToken = localStorage.getItem('authToken') || ''
    shutdownEnvironment(authToken, id)
    this.isShutdownOrStartConfirmationModalOpen = false
  }

  async deleteEnvironment(id: number): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    await deleteEnvironment(authToken, id)
    this.isDeleteConfirmationModalOpen = false
  }

  startEnvironment(id: number): void {
    const authToken = localStorage.getItem('authToken') || ''
    startEnvironment(authToken, id)
    this.isShutdownOrStartConfirmationModalOpen = false
  }

  restartEnvironment(id: number): void {
    const authToken = localStorage.getItem('authToken') || ''
    restartEnvironment(authToken, id)
    this.isRestartConfirmationModalOpen = false
  }

  shutdownOrStart(id: number, status: string): void {
    if (['ready', 'creating', 'starting'].includes(status)) {
      this.shutdownEnvironment(id)
    } else {
      this.startEnvironment(id)
    }
  }
  statusColor(id: number): string {
    const environment = this.environments.find((e) => e.id === id)
    if (environment!.status !== 'ready') {
      return '#718096'
    } else {
      return '#374151'
    }
  }

  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('@/assets/animations/kea-loading.json'),
  }

  openLink(url: string): void {
    window.open(url, '_blank')
  }

  async getModels(): Promise<void> {
    const authToken = localStorage.getItem('authToken') || ''
    this.models = await getModels(authToken)
    this.interval = setInterval(async () => {
      this.models = await getModels(authToken)
    }, 5_000)
  }

  getImgUrl(framework: string): string {
    var images = require.context('../assets/svg', false, /\.svg$/)
    return images('./' + framework + '.svg')
  }

  deleteModel(modelId: number): void {
    console.log('delete model ID: ' + modelId)
  }

  viewDetailsModel(model: Model): void {
    this.$router.push(`/models/${model.id}`)
  }

  openDeleteConfirmationModelModal(model: Model): void {
    this.focusedModel = model
    this.isDeleteConfirmationModalOpen = true
  }

  getLastDeploy(deployments: Deployment[]): string {
    if (deployments.length != 0) {
      return deployments[0].status
    } else {
      return ''
    }
  }

  getLastSuccessfulDeploy(deployments: Deployment[]): Deployment | undefined {
    return deployments.find((d) => d.status === 'ready')
  }

  copyEndpoint(unique_id: string, index: number): void {
    const url = `${process.env.VUE_APP_KEA_API}/models/${unique_id}/predict`
    navigator.clipboard.writeText(url)
    this.copied = index
    setTimeout(() => {
      this.copied = -1
    }, 3000)
  }
}
