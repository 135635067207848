




import { Vue, Component } from 'vue-property-decorator'
import { registerBitBucket } from '@/api/oauth'

@Component
export default class OauthCallback extends Vue {
  errorMessage = ''
  mounted(): void {
    this.registerBitBucket()
  }

  async registerBitBucket(): Promise<void> {
    const code = this.$route.query.code
    const authToken = localStorage.getItem('authToken') || ''
    await registerBitBucket(code, authToken)
      .then(() => {
        this.$router.push('/settings')
      })
      .catch((err: any) => {
        this.errorMessage = err.charAt(0).toUpperCase + err.slice(1)
      })
  }
}
