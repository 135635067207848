




































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class KeaSelector extends Vue {
  @Prop() placeholder!: string
  @Prop() options!: string[]

  isOptionsExpanded = false
  selectedOption = this.placeholder

  setOption(option: any): void {
    this.selectedOption = option
    this.isOptionsExpanded = false
    this.$emit('option-selected', this.selectedOption)
  }
}
