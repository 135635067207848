

































































































































































































import { Vue, Component } from 'vue-property-decorator'
import NavBar from '@/components/NavBar.vue'
import KeaButton from '@/components/KeaButton.vue'
import NavStorages from '@/components/NavStorages.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import KeaAlert from '@/components/KeaAlert.vue'

import {
  StorageBucket,
  StorageFile,
  getStorage,
  deleteFile,
} from '@/api/storage'

@Component({
  components: {
    NavBar,
    KeaButton,
    NavStorages,
    ConfirmationModal,
    KeaAlert,
  },
})
export default class Storage extends Vue {
  showDropdown = false
  route = Number(this.$route.params.id)
  storage: StorageBucket = {
    id: 0,
    name: '',
    files: null,
  }
  loading = true
  pendingDelete = false

  focusedFile: StorageFile | null = null
  isDeleteConfirmationModalOpen = false

  async getStorage(): Promise<any> {
    const authToken = localStorage.getItem('authToken') || ''
    this.storage = await getStorage(this.route, authToken)
    this.loading = false
  }

  mounted(): void {
    this.getStorage()
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown
  }

  uploadFiles(): void {
    this.$router.push('/storages/' + this.storage.id + '/upload')
  }

  openDeleteConfirmationModal(file: StorageFile): void {
    this.focusedFile = file
    this.isDeleteConfirmationModalOpen = true
  }

  async modalDeleteFile(name: string): Promise<void> {
    this.pendingDelete = true
    const authToken = localStorage.getItem('authToken') || ''
    await deleteFile(authToken, this.storage.id, name)
    this.isDeleteConfirmationModalOpen = false
    this.getStorage()
    this.pendingDelete = false
  }

  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('@/assets/animations/kea-loading.json'),
  }
}
