


































































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { register } from '@/api/authentication'
import { acceptTeamInvite } from '@/api/teams'
import KeaButton from '@/components/KeaButton.vue'

@Component({
  components: {
    KeaButton,
  },
})
export default class Register extends Vue {
  @Prop() email!: string
  @Prop() teamInvite!: string
  private isPasswordVisible = false
  password = ''
  firstName = ''
  lastName = ''
  newsletterOptIn = true
  termsAndConditions = false
  emptyFirstName = false
  emptyLastName = false
  emptyEmail = false
  emptyPassword = false
  emptyTermsAndConditions = false
  private icons = {
    mdiEyeOutline,
    mdiEyeOffOutline,
  }
  errorMessage = ''

  register(): void {
    this.emptyFirstName = !this.firstName
    this.emptyLastName = !this.lastName
    this.emptyEmail = !this.email
    this.emptyPassword = !this.password
    this.emptyTermsAndConditions = !this.termsAndConditions
    if (
      this.firstName &&
      this.lastName &&
      this.email &&
      this.password &&
      this.termsAndConditions
    ) {
      register({
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        password: this.password,
        newsletter_opt_in: this.newsletterOptIn,
        is_invited: this.teamInvite ? true : false,
      })
        .then(() => {
          if (this.teamInvite) {
            acceptTeamInvite(this.teamInvite)
          }
          this.$router.push('/login')
        })
        .catch((err) => {
          this.errorMessage = err.charAt(0).toUpperCase() + err.slice(1)
        })
    }
  }
}
