













































































































































































































import { Vue, Component } from 'vue-property-decorator'
import { passwordReset, passwordResetClaim } from '@/api/authentication'
import KeaButton from '@/components/KeaButton.vue'
import KeaAlert from '@/components/KeaAlert.vue'

@Component({
  components: {
    KeaButton,
    KeaAlert,
  },
})
export default class PasswordReset extends Vue {
  email = ''
  password = ''
  errorMessage = ''
  successMessage = ''
  emptyEmail = false
  emptyPassword = false
  hasCode = false

  passwordReset(): void {
    this.errorMessage = ''
    this.successMessage = ''
    if (this.email === '') {
      this.emptyEmail = true
    } else {
      passwordReset(this.email)
        .then(() => {
          this.successMessage =
            'An email to reset the password has been sent to you.'
        })
        .catch(() => {
          this.errorMessage = 'Something went wrong. Try again.'
        })
    }
  }
  passwordResetClaim(): void {
    this.errorMessage = ''
    this.successMessage = ''
    let uniqueID = this.$route.query.code as string
    if (this.password === '') {
      this.emptyPassword = true
    } else {
      passwordResetClaim(this.password, uniqueID)
        .then(() => {
          this.successMessage =
            'Your password has been changed. Go to login page to access your account.'
        })
        .catch(() => {
          this.errorMessage = 'Something went wrong. Try again.'
        })
    }
  }

  mounted() {
    let code = this.$route.query.code
    if (code) {
      this.hasCode = true
    }
    return code
  }
  updated() {
    let code = this.$route.query.code
    if (code) {
      this.hasCode = true
    }
    return code
  }
}
