import axios from 'axios'

export interface Model {
  id: number
  name: string
  created_at: string
  framework: string
  last_deployment_url: string
  deployments: Deployment[]
}

export interface Deployment {
  id: number
  created_at: string
  version: string
  status: string
  unique_id: string
}

export async function getModels(authToken: string): Promise<Model[]> {
  const response = await axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/models`, {
      headers: {
        Authorization: authToken,
      },
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return response.data
}

export async function getModel(authToken: string, id: string): Promise<Model> {
  const response = await axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/models/${id}`, {
      headers: {
        Authorization: authToken,
      },
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return response.data
}