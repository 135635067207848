






















import { validateTeamInvite } from '@/api/teams'
import { Vue, Component } from 'vue-property-decorator'
import Register from './Register.vue'

@Component({ components: { Register } })
export default class AcceptInvite extends Vue {
  email = ''
  inviteID = ''
  errorMessage = ''

  async validateInvite(): Promise<void> {
    this.inviteID = this.$route.query.invite as string
    const invite = await validateTeamInvite(this.inviteID).catch(() => {
      this.errorMessage = "Something wen't wrong"
    })
    this.email = invite ? invite.email : ''
  }

  async mounted(): Promise<void> {
    this.validateInvite()
  }
}
