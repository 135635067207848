import axios, { AxiosRequestConfig } from 'axios'
import FormData from 'form-data'

export interface StorageFile {
  Name: string
  Size: number
}
export interface StorageBucket {
  id: number
  name: string
  files?: StorageFile[] | null
}

export function createStorageBucket(
  name: string,
  authToken: string
): Promise<any> {
  return axios
    .post(
      `${process.env.VUE_APP_KEA_API}/v1/storages`,
      {
        name,
      },
      { headers: { Authorization: authToken } }
    )
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export async function getStorage(
  id: number,
  authToken: string
): Promise<StorageBucket> {
  const response = await axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/storages/${id}`, {
      headers: { Authorization: authToken },
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return response.data
}

export async function uploadFiles(
  id: number,
  authToken: string,
  files: FormData
): Promise<void> {
  const config: AxiosRequestConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${process.env.VUE_APP_KEA_API}/v1/storages/${id}/upload`,
    headers: {
      Authorization: authToken,
      'Content-Type': 'multipart/form-data',
    },
    data: files,
  }
  return axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data))
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })
}

export async function deleteStorage(
  authToken: string,
  id: number
): Promise<boolean> {
  await axios
    .delete(`${process.env.VUE_APP_KEA_API}/v1/storages/${id}`, {
      headers: { Authorization: authToken },
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return true
}

export async function deleteFile(
  authToken: string,
  id: number,
  fileName: string
): Promise<boolean> {
  await axios
    .delete(`${process.env.VUE_APP_KEA_API}/v1/storages/${id}/${fileName}`, {
      headers: { Authorization: authToken },
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return true
}
