import axios from 'axios'

export interface Storage {
  id: number
  name: string
}

export interface StorageBucket {
  id: number
  name: string
  size: number
}

export async function getStorages(authToken: string): Promise<Storage[]> {
  const response = await axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/storages`, {
      headers: {
        Authorization: authToken,
      },
    })
    .catch((error) => {
      return Promise.reject(error.response.data.error)
    })

  return response.data
}
