

















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class KeaAlert extends Vue {
  @Prop() text!: string
  @Prop() readonly type!: string

  get icono(): any {
    switch (this.type) {
      case 'success':
        return 'M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
      case 'error':
        return 'M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
      case 'info':
        return 'M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
      default:
        return ''
    }
  }

  get color(): string {
    switch (this.type) {
      case 'success':
        return 'rounded-lg py-5 px-6 text-base text-green-700 inline-flex items-center w-full mt-5 bg-green-100'
      case 'error':
        return 'rounded-lg py-5 px-6 text-base text-red-700 inline-flex items-center w-full mt-5 bg-red-100'
      case 'info':
        return 'rounded-lg py-5 px-6 text-base text-blue-700 inline-flex items-center w-full mt-5 bg-blue-100'
      default:
        return ''
    }
  }
}
