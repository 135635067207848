import axios from 'axios'

export interface InstanceType {
  name: string
  vcpus: number
  ram: number
  gpu_type: string
  price_per_hour: number
}

export async function getCatalog(authToken: string): Promise<InstanceType[]> {
  const response = await axios
    .get(`${process.env.VUE_APP_KEA_API}/v1/catalog`, {
      headers: { Authorization: authToken },
    })
    .catch((err) => {
      return Promise.reject(err.response.data.error)
    })

  return response.data
}
