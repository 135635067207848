








































































































































































import { Vue, Component } from 'vue-property-decorator'
import KeaAlert from './KeaAlert.vue'
import { createTeamApiKey } from '@/api/teams'

@Component({
  components: {
    KeaAlert,
  },
})
export default class NewApiKeyModal extends Vue {
  name = ''
  errorMessage = ''

  validateName(name: string): boolean {
    const regex = /^(?=.{3,15}$)(^[a-zA-Z0-9-]*$)+$/
    if (!regex.test(name)) {
      return false
    }
    return true
  }

  close(): void {
    this.$emit('close')
  }
  async confirm(): Promise<void> {
    if (!this.validateName(this.name)) {
      this.errorMessage =
        'Name must be between 3 and 15 characters and can only contain letters, numbers and hyphens.'
      return
    }
    const authToken = localStorage.getItem('authToken') || ''
    createTeamApiKey(authToken, this.name)
      .then(() => {
        this.$emit('confirm')
        this.close()
      })
      .catch((err) => {
        this.errorMessage = err.charAt(0).toUpperCase() + err.slice(1)
      })
  }
}
